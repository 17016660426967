export const environment = {
    banorte: false,
    production: true,
    ente: "H. Ayuntamiento de Choix",
    administracion: "2024 - 2027",
    url_escudo: "./assets/choix/escudo.png",
    url_administracion: "./assets/choix/logo_admin_2024_2027.png",
    url_footer: "./assets/choix/logo_admin_2024_2027.png",
    direcciones: [
        "Calle. B Juarez",
        "C.P. 81700",
        "Choix, Sinaloa"
    ],
    telefonos: [
        "698 866 1048"
    ],
    emails: [
        "tesoreria@choix.gob.mx"
    ],
    url_aviso: "https://www.choix.gob.mx/",
    url_webapi: "https://pagoschoix.azurewebsites.net/api/",
    url_sitio: "https://pagoschoix.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "001",
    municipio: "CHOIX",
    firebase: {
        apiKey: "AIzaSyDWVCCQgW0uuPa2dngXmY0n6fwE2cpv8Eg",
        authDomain: "pagoschoix.firebaseapp.com",
        projectId: "pagoschoix",
        storageBucket: "pagoschoix.appspot.com",
        messagingSenderId: "627965936263",
        appId: "1:627965936263:web:b571f82e64288baf16ee22",
        measurementId: "G-3C74G2F9ZD"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Choix para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/choix/',
    img_ayuda_2: '../assets/choix/',
    img_ayuda_3: '../assets/choix/'
};